import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-newBackgroundColor text-newTextColor py-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          <div>
            <h3 className="text-3xl font-bold mb-6 text-newAccentColor">NatGen Contractors</h3>
            <p className="mb-6 text-lg">Building dreams and shaping skylines since 1995.</p>
            <div className="flex space-x-4">
              <a href="#" className="text-newTextColor hover:text-newAccentColor transition duration-300">
                <FaFacebook size={28} />
              </a>
              <a href="#" className="text-newTextColor hover:text-newAccentColor transition duration-300">
                <FaTwitter size={28} />
              </a>
              <a href="#" className="text-newTextColor hover:text-newAccentColor transition duration-300">
                <FaLinkedin size={28} />
              </a>
              <a href="#" className="text-newTextColor hover:text-newAccentColor transition duration-300">
                <FaInstagram size={28} />
              </a>
            </div>
          </div>
          <div>
            <h4 className="text-2xl font-semibold mb-6">Quick Links</h4>
            <ul className="space-y-3">
              <li><Link to="/" className="hover:text-accent transition duration-300">Home</Link></li>
              <li><Link to="/services" className="hover:text-accent transition duration-300">Services</Link></li>
              <li><Link to="/projects" className="hover:text-accent transition duration-300">Projects</Link></li>
              <li><Link to="/about" className="hover:text-accent transition duration-300">About Us</Link></li>              
              <li><Link to="/contact" className="hover:text-accent transition duration-300">Contact</Link></li>
            </ul>
          </div>
          <div>
            <h4 className="text-2xl font-semibold mb-6">Our Services</h4>
            <ul className="space-y-3">
              <li>Residential Construction</li>
              <li>Commercial Construction</li>
              <li>Industrial Projects</li>
              <li>Renovations</li>
              <li>Project Management</li>
              <li>Design-Build Services</li>
              <li>Sustainable Construction</li>
            </ul>
          </div>
          <div>
            <h4 className="text-2xl font-semibold mb-6">Contact Us</h4>
            <p className="mb-2">Eastern bypass, Ruiru, Kenya, 00232</p>
            <p className="mb-2">Mobile: 0720 210182</p>
            <Link to="/contact" className="bg-accent text-textDark font-bold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300">
              Get a Quote
            </Link>
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-700 text-center">
          <p>&copy; {new Date().getFullYear()} NatGen Contractors. All rights reserved.</p>
        </div>

      </div>
    </footer>
  );
};

export default Footer;