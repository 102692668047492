import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const ProjectCounter = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const counters = [
    { label: 'Projects Completed', endValue: 500, suffix: '+' },
    { label: 'Happy Clients', endValue: 350, suffix: '+' },
    { label: 'Team Members', endValue: 50, suffix: '' },
    { label: 'Years of Experience', endValue: 25, suffix: '+' },
  ];

  return (
    <section ref={ref} className="py-16 bg-primary text-textLight">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {counters.map((counter, index) => (
            <CounterItem key={index} inView={inView} {...counter} />
          ))}
        </div>
      </div>
    </section>
  );
};

const CounterItem = ({ label, endValue, suffix, inView }) => {
  const [count, setCount] = useState(0);
  const countRef = useRef(null);

  useEffect(() => {
    if (inView) {
      const duration = 2000; // 2 seconds
      const steps = 60;
      const increment = endValue / steps;
      let current = 0;
      const timer = setInterval(() => {
        current += increment;
        if (current >= endValue) {
          clearInterval(timer);
          current = endValue;
        }
        setCount(Math.floor(current));
      }, duration / steps);

      return () => clearInterval(timer);
    }
  }, [inView, endValue]);

  return (
    <motion.div
      className="text-center"
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <div className="text-4xl md:text-5xl font-bold mb-2">
        <span ref={countRef}>{count}</span>
        {suffix}
      </div>
      <div className="text-xl text-accent">{label}</div>
    </motion.div>
  );
};

export default ProjectCounter;