import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaHome, FaBuilding, FaIndustry, FaTools, FaLeaf, FaPencilRuler } from 'react-icons/fa';
import { Helmet } from 'react-helmet'; // Import react-helmet

const services = [
  { 
    id: 'residential',
    title: 'Residential Construction', 
    description: 'Crafting dream homes with precision and care. From modern apartments to luxurious villas, we bring your vision to life.',
    icon: FaHome,
    color: '#4CAF50'
  },
  { 
    id: 'commercial',
    title: 'Commercial Construction', 
    description: 'Building the future of business. Our commercial projects range from sleek office spaces to expansive retail centers.',
    icon: FaBuilding,
    color: '#2196F3'
  },
  { 
    id: 'industrial',
    title: 'Industrial Projects', 
    description: 'Powering progress through robust industrial facilities. We construct warehouses, factories, and processing plants built to last.',
    icon: FaIndustry,
    color: '#FF9800'
  },
  { 
    id: 'renovation',
    title: 'Renovation & Remodeling', 
    description: 'Breathing new life into existing structures. Our renovation services transform outdated spaces into modern marvels.',
    icon: FaTools,
    color: '#9C27B0'
  },
  { 
    id: 'sustainable',
    title: 'Sustainable Building', 
    description: 'Constructing with conscience. We specialize in eco-friendly building practices and LEED-certified constructions.',
    icon: FaLeaf,
    color: '#8BC34A'
  },
  { 
    id: 'design',
    title: 'Design-Build Services', 
    description: 'From concept to completion. Our integrated design-build approach ensures seamless project execution and superior results.',
    icon: FaPencilRuler,
    color: '#FF5722'
  }
];

const Services = () => {
  const [activeService, setActiveService] = useState(null);

  return (
    <div className="min-h-screen bg-bgLight py-24 px-4">
      <Helmet>
        <title>Our Services - Your Company Name</title>
        <meta name="description" content="Explore our range of construction services including residential, commercial, industrial, renovation, sustainable building, and design-build services." />
        <meta property="og:title" content="Our Services" />
        <meta property="og:description" content="Explore our range of construction services including residential, commercial, industrial, renovation, sustainable building, and design-build services." />
        <meta property="og:image" content="URL_TO_YOUR_IMAGE" /> {/* Replace with your image URL */}
        <meta property="og:url" content="https://yourwebsite.com/services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Construction Services",
            "provider": {
              "@type": "Organization",
              "name": "Your Company Name",
              "url": "https://yourwebsite.com",
              "logo": "URL_TO_YOUR_LOGO" // Replace with your logo URL
            },
            "areaServed": {
              "@type": "Place",
              "name": "Your Service Area"
            },
            "offers": services.map(service => ({
              "@type": "Offer",
              "name": service.title,
              "description": service.description,
              "url": `https://yourwebsite.com/services#${service.id}`
            }))
          })}
        </script>
      </Helmet>

      <motion.h1 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-5xl font-bold mb-16 text-center text-primary"
      >
        Our Services
      </motion.h1>

      <div className="max-w-6xl mx-auto">
        <motion.div 
          className="grid grid-cols-2 md:grid-cols-3 gap-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {services.map((service) => (
            <motion.div
              key={service.id}
              className="relative aspect-square cursor-pointer"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setActiveService(service.id === activeService ? null : service.id)}
            >
              <div 
                className="absolute inset-0 rounded-2xl"
                style={{ backgroundColor: service.color, opacity: 0.2 }}
              />
              <div className="absolute inset-0 flex flex-col items-center justify-center p-6 text-center">
                <service.icon className="text-5xl mb-4" style={{ color: service.color }} />
                <h3 className="text-xl font-semibold mb-2 text-primary">{service.title}</h3>
              </div>
            </motion.div>
          ))}
        </motion.div>

        <AnimatePresence>
          {activeService && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mt-12"
            >
              <div className="bg-white rounded-2xl shadow-xl p-8">
                <h3 className="text-2xl font-bold mb-4 text-primary">
                  {services.find(s => s.id === activeService).title}
                </h3>
                <p className="text-lg text-gray-700">
                  {services.find(s => s.id === activeService).description}
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Services;