import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import { supabase } from '../supabaseConfig';
import { FaEdit, FaTrash, FaSpinner, FaHeart, FaEye } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const PodcastItem = ({ podcast, session, handleLike, handleDelete, handleView }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once
    threshold: 0.5, // 50% of the video in view
  });
  const [hasViewed, setHasViewed] = useState(false); // Track if the view has been recorded

  // Increment views once when the video comes into view
  useEffect(() => {
    if (inView && !hasViewed) {
      handleView(podcast.id, podcast.views);
      setHasViewed(true);
    }
  }, [inView, hasViewed, podcast.id, podcast.views, handleView]);

  return (
    <motion.article
      className="relative bg-white rounded-lg shadow-lg overflow-hidden group"
      whileHover={{ scale: 1.05 }}
      ref={ref}
    >
      <div className="relative overflow-hidden">
        {/* Video with increased height */}
        <div className="h-96 rounded-lg overflow-hidden transition-transform duration-300 group-hover:scale-105">
          <ReactPlayer
            url={podcast.youtube_url}
            width="100%"
            height="100%"
            light={`https://img.youtube.com/vi/${getYouTubeID(podcast.youtube_url)}/maxresdefault.jpg`}
            playing={inView} // Autoplay when in view
            muted
            controls
            className="react-player"
          />
        </div>

        {/* Hover overlay with pointer-events set to none */}
        <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-center items-center pointer-events-none">
          <h2 className="text-white text-2xl font-semibold text-center mb-4">{podcast.title}</h2>
          <div className="flex space-x-4">
            <span className="text-gray-300 flex items-center">
              <FaEye className="mr-1" /> {podcast.views}
            </span>
            <span className="text-gray-300 flex items-center">
              <FaHeart className="mr-1" /> {podcast.likes}
            </span>
          </div>
        </div>
      </div>

      {/* Podcast Details */}
      <div className="p-4">
        <p className="text-gray-600 mb-4 line-clamp-3">{podcast.description}</p>
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => handleLike(podcast.id, podcast.likes)}
              className="text-red-600 hover:text-red-800 transition-colors duration-300 flex items-center"
            >
              <FaHeart className="mr-1" />
              {podcast.likes} {/* Display the current number of likes */}
            </button>
            {session && (
              <>
                <Link
                  to={`/edit-podcast/${podcast.id}`}
                  className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
                >
                  <FaEdit />
                </Link>
                <button
                  onClick={() => handleDelete(podcast.id)}
                  className="text-red-600 hover:text-red-800 transition-colors duration-300"
                >
                  <FaTrash />
                </button>
              </>
            )}
          </div>
          <span className="text-sm text-gray-500">
            {new Date(podcast.created_at).toLocaleDateString()}
          </span>
        </div>
      </div>
    </motion.article>
  );
};

const PodcastList = () => {
  const [podcasts, setPodcasts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [session, setSession] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    document.title = 'Featured Podcasts | Podcast App'; 
    fetchPodcasts();
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
    };
    getSession();
  }, []);

  const fetchPodcasts = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('podcast')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setPodcasts(data);
    } catch (error) {
      console.error('Error fetching podcasts:', error);
      setError('Failed to load podcasts. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLike = async (id, currentLikes) => {
    try {
      await supabase
        .from('podcast')
        .update({ likes: currentLikes + 1 }) // Increment likes in the database
        .eq('id', id);
      setPodcasts(podcasts.map(p => (p.id === id ? { ...p, likes: currentLikes + 1 } : p))); // Update state
    } catch (error) {
      console.error('Error liking podcast:', error);
    }
  };

  const handleView = async (id, currentViews) => {
    try {
      await supabase
        .from('podcast')
        .update({ views: currentViews + 1 })
        .eq('id', id);
      setPodcasts(podcasts.map(p => (p.id === id ? { ...p, views: currentViews + 1 } : p)));
    } catch (error) {
      console.error('Error updating views:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this podcast?')) {
      try {
        const { error } = await supabase
          .from('podcast')
          .delete()
          .eq('id', id);

        if (error) throw error;
        setPodcasts(podcasts.filter(podcast => podcast.id !== id));
      } catch (error) {
        console.error('Error deleting podcast:', error);
        alert('Failed to delete the podcast. Please try again.');
      }
    }
  };

  const filteredPodcasts = podcasts.filter(podcast =>
    podcast.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-4xl text-indigo-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    );
  }

  return (
    <main className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <header>
          <h1 className="text-4xl font-bold text-gray-900 mb-12 text-center mt-16">Featured Podcasts</h1>
        </header>
        <section>
          <div className="mb-8 flex justify-center">
            <input
              type="text"
              placeholder="Search podcasts..."
              className="w-full max-w-md p-4 rounded-lg border border-gray-300 shadow-md"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {filteredPodcasts.length === 0 ? (
            <p className="text-gray-600 text-center">No results found for "{searchTerm}".</p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {filteredPodcasts.map((podcast) => (
                <PodcastItem
                  key={podcast.id}
                  podcast={podcast}
                  session={session}
                  handleLike={handleLike}
                  handleDelete={handleDelete}
                  handleView={handleView}
                />
              ))}
            </div>
          )}
        </section>
      </div>
    </main>
  );
};

// Helper function to extract YouTube ID from URL
const getYouTubeID = (url) => {
  const regExp = /^.*(youtu\.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

export default PodcastList;
