import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseConfig';
import { FaSpinner } from 'react-icons/fa';
import Helmet from 'react-helmet'; // Import Helmet for SEO

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    try {
      const { data, error } = await supabase
        .from('blog')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;

      setBlogPosts(data);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
      setError('Failed to load blog posts. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-4xl text-indigo-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Our Blog - Stay Updated with the Latest Posts</title>
        <meta name="description" content="Read our latest blog posts on various topics. Stay informed and engaged with our content." />
        <meta name="keywords" content="blog, articles, news, updates" />
        <link rel="canonical" href="https://NatGenContractors.com/blogs" />
      </Helmet>
      <div className="bg-gray-100 min-h-screen pt-20 pb-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-12 text-center" role="heading" aria-level="1">Our Blog</h1>
          {blogPosts.length === 0 ? (
            <p className="text-gray-600 text-center">No blog posts available at the moment.</p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {blogPosts.map((post) => (
                <div key={post.id} className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl flex flex-col">
                  <Link to={`/blog/${post.id}`} className="flex-grow" aria-label={`Read more about ${post.title}`}>
                    <div className="p-6 flex flex-col h-full">
                      <h2 className="text-2xl font-semibold text-gray-900 mb-2" role="heading" aria-level="2">{post.title}</h2>
                      <p className="text-gray-600 flex-grow mb-4">
                        {post.content.substring(0, 150)}{post.content.length > 150 ? '...' : ''}
                      </p>
                      <div className="flex justify-between items-center mt-4">
                        <span className="text-sm text-gray-500">{new Date(post.created_at).toLocaleDateString()}</span>
                        <span className="text-sm text-gray-500">By {post.author}</span>
                      </div>
                    </div>
                  </Link>
                  <div className="bg-indigo-600 text-white text-center py-2">
                    <Link to={`/blog/${post.id}`} className="font-semibold">Read More</Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Blog;