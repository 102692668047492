import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const projectCategories = [
  { id: 'all', name: 'All Projects', color: '#3498db' },
  { id: 'residential', name: 'Residential', color: '#e74c3c' },
  { id: 'commercial', name: 'Commercial', color: '#2ecc71' },
  { id: 'industrial', name: 'Industrial', color: '#f39c12' },
  { id: 'renovation', name: 'Renovation', color: '#9b59b6' },
];

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    // Simulating dynamic fetching of projects
    const fetchProjects = async () => {
      // In a real scenario, this would be an API call
      const dummyProjects = [
        { id: 1, title: 'Greenview Apartments', category: 'residential', image: 'project9.jpg' },
        { id: 2, title: 'Nairobi Central Business Hub', category: 'commercial', image: 'project2.jpg' },
        { id: 3, title: 'Heritage Hotel Restoration', category: 'renovation', image: 'project10.jpg' },
        { id: 4, title: 'Modern Family Home', category: 'residential', image: 'project21.jpg' },
        { id: 5, title: 'Tech Startup Office Space', category: 'commercial', image: 'project24.jpg' },
        // Additional projects
        { id: 6, title: 'Urban Loft', category: 'residential', image: 'project17.jpg' },
        { id: 7, title: 'Luxury Villa', category: 'residential', image: 'project22.jpg' },
        { id: 8, title: 'Eco-Friendly Office', category: 'commercial', image: 'project7.jpg' },
        { id: 9, title: 'Corporate Headquarters', category: 'commercial', image: 'project28.jpg' },
        { id: 10, title: 'Industrial Warehouse', category: 'industrial', image: 'project24.jpg' },
        { id: 11, title: 'Manufacturing Plant', category: 'industrial', image: 'project18.jpg' },
        { id: 12, title: 'Renovated Historic Building', category: 'renovation', image: 'project7.jpg' },
        { id: 13, title: 'Modern Office Renovation', category: 'renovation', image: 'project6.jpg' },
      ];
      setProjects(dummyProjects);
    };

    fetchProjects();
  }, []);

  const filteredProjects = filter === 'all' 
    ? projects 
    : projects.filter(project => project.category === filter);

  return (
    <div className="min-h-screen bg-gray-100 py-16 px-4">
      <motion.h1 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="text-5xl font-bold mb-12 text-center text-gray-800"
      >
        Our Spectacular Projects
      </motion.h1>

      <div className="flex justify-center mb-8 flex-wrap">
        {projectCategories.map((category) => (
          <motion.button
            key={category.id}
            onClick={() => setFilter(category.id)}
            className={`m-2 px-6 py-2 rounded-full text-white font-semibold transition duration-300`}
            style={{ backgroundColor: category.color }}
            whileHover={{ scale: 1.05, boxShadow: "0px 5px 15px rgba(0,0,0,0.1)" }}
            whileTap={{ scale: 0.95 }}
          >
            {category.name}
          </motion.button>
        ))}
      </div>

      <motion.div 
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"
        layout
      >
        {filteredProjects.map((project) => (
          <motion.div
            key={project.id}
            layout
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300"
          >
            <motion.img 
              src={project.image} 
              alt={project.title} 
              className="w-full h-64 object-cover"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            />
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{project.title}</h3>
              <p className="text-gray-600 capitalize">{project.category}</p>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}

export default Projects;