import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseConfig';
import { FaEdit, FaTrash, FaSpinner, FaYoutube, FaUser, FaFolder } from 'react-icons/fa';
import { Helmet } from 'react-helmet'; // Import react-helmet

const Podcast = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [podcast, setPodcast] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [session, setSession] = useState(null);

  useEffect(() => {
    const fetchPodcast = async () => {
      try {
        const { data, error } = await supabase
          .from('podcast')
          .select('*')
          .eq('id', id)
          .single();

        if (error) throw error;
        
        console.log("Fetched podcast data:", data);
        setPodcast(data);
      } catch (error) {
        console.error('Error fetching podcast:', error.message);
        setError('Failed to load podcast details.');
      } finally {
        setIsLoading(false);
      }
    };

    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
    };

    fetchPodcast();
    getSession();
  }, [id]);

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this podcast?')) {
      try {
        const { error } = await supabase
          .from('podcast')
          .delete()
          .eq('id', id);

        if (error) throw error;

        navigate('/podcast');
      } catch (error) {
        console.error('Error deleting podcast:', error);
        alert('Failed to delete the podcast. Please try again.');
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-6xl text-indigo-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    );
  }

  if (!podcast) return null;

  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>{podcast.title} - Podcast</title>
        <meta name="description" content={podcast.description} />
        <meta property="og:title" content={podcast.title} />
        <meta property="og:description" content={podcast.description} />
        <meta property="og:image" content={podcast.cover_image_url} />
        <meta property="og:url" content={`https://yourwebsite.com/podcast/${podcast.id}`} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "PodcastEpisode",
            "name": podcast.title,
            "description": podcast.description,
            "url": `https://yourwebsite.com/podcast/${podcast.id}`,
            "image": podcast.cover_image_url,
            "author": {
              "@type": "Person",
              "name": podcast.author
            },
            "datePublished": podcast.published_at // Ensure this field exists in your data
          })}
        </script>
      </Helmet>
      <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="md:flex">
          <div className="md:flex-shrink-0">
            {podcast.youtube_url ? (
              <div className="h-0 pb-9/16 md:w-96 relative">
                <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  src={`https://www.youtube.com/embed/${new URL(podcast.youtube_url).searchParams.get('v')}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={podcast.title} // SEO improvement
                  aria-label={`Video for ${podcast.title}`} // Accessibility improvement
                  description={podcast.description} // SEO improvement
                ></iframe>
              </div>
            ) : (
              <img 
                className="h-48 w-full object-cover md:h-full md:w-96"
                src={podcast.cover_image_url} 
                alt={podcast.title} 
              />
            )}
          </div>
          <div className="p-8">
            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
              {podcast.category}
            </div>
            <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {podcast.title}
            </h1>
            <p className="mt-4 text-lg text-gray-500">
              {podcast.description}
            </p>
            <div className="mt-6 flex items-center">
              <FaUser className="text-gray-400 mr-2" />
              <span className="text-sm font-medium text-gray-500">{podcast.author}</span>
            </div>
            <div className="mt-2 flex items-center">
              <FaFolder className="text-gray-400 mr-2" />
              <span className="text-sm font-medium text-gray-500">{podcast.category}</span>
            </div>
            {session && (
              <div className="mt-6 flex space-x-3">
                <Link 
                  to={`/edit-podcast/${podcast.id}`} 
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <FaEdit className="mr-2" /> Edit
                </Link>
                <button 
                  onClick={handleDelete} 
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <FaTrash className="mr-2" /> Delete
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Podcast;