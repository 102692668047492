import React from 'react';
import { Link } from 'react-router-dom';

const BlogPreview = () => {
  const blogPosts = [
    { id: 1, title: 'Top 5 Home Renovation Trends', excerpt: 'Discover the latest trends in home renovation for 2023...', image: '/images/blog1.jpg' },
    { id: 2, title: 'Sustainable Building Materials', excerpt: 'Learn about eco-friendly materials that are revolutionizing construction...', image: '/images/blog2.jpg' },
    { id: 3, title: 'Commercial Space Design Tips', excerpt: 'Create an inspiring workspace with these design tips...', image: '/images/blog3.jpg' },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {blogPosts.map((post) => (
        <div key={post.id} className="bg-white rounded-lg shadow-md overflow-hidden">
          <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
          <div className="p-6">
            <h3 className="text-xl font-semibold mb-2">{post.title}</h3>
            <p className="text-gray-600 mb-4">{post.excerpt}</p>
            <Link to={`/blog/${post.id}`} className="text-secondary hover:underline">Read More</Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogPreview;