import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus, FaBlog, FaPodcast, FaChartLine, FaSignOutAlt, FaEdit, FaSearch } from 'react-icons/fa';
import { supabase } from '../supabaseConfig';

const DashboardCard = ({ title, icon, link, bgColor }) => (
  <Link to={link} className={`${bgColor} p-6 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl`}>
    <div className="flex items-center justify-between">
      <div className="text-white text-xl font-bold">{title}</div>
      {icon}
    </div>
  </Link>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const [podcasts, setPodcasts] = useState([]);
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [ageBracket, setAgeBracket] = useState(''); // Add state for age bracket
  const [sortOrder, setSortOrder] = useState('asc'); // Add state for sort order

  useEffect(() => {
    fetchPodcasts();
    fetchLeads();
  }, []);

  const fetchPodcasts = async () => {
    try {
      const { data, error } = await supabase
        .from('podcast')
        .select('*');

      if (error) throw error;

      setPodcasts(data);
    } catch (error) {
      console.error('Error fetching podcasts:', error);
    }
  };

  const fetchLeads = async () => {
    try {
      const { data, error } = await supabase
        .from('leads')
        .select('*');

      if (error) throw error;

      setLeads(data);
    } catch (error) {
      console.error('Error fetching leads:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/'); // Redirect to homepage after successful sign-out
    } catch (error) {
      console.error('Error signing out:', error);
      alert('Failed to sign out. Please try again.');
    }
  };

  // Add a function to filter leads by age bracket
  const filteredLeads = leads.filter(lead => {
    const matchesSearch = lead.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          lead.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          lead.inquiry.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesAgeBracket = ageBracket ? lead.age_bracket === ageBracket : true; // Correctly using age_bracket
    return matchesSearch && matchesAgeBracket;
  });

  // Add sorting functionality
  const sortedLeads = [...filteredLeads].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner or skeleton
  }

  return (
    <div className="bg-gray-100 min-h-screen pt-20 pb-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-4 sm:mb-0">Dashboard</h1>
          <button 
            onClick={handleSignOut}
            className="bg-red-500 text-white px-4 py-2 rounded-full flex items-center transition duration-300 ease-in-out hover:bg-red-600"
          >
            <FaSignOutAlt className="mr-2" />
            Sign Out
          </button>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <DashboardCard 
            title="Add New Blog" 
            icon={<FaPlus className="text-white text-2xl" />} 
            link="/add-blog" 
            bgColor="bg-blue-500"
          />
          <DashboardCard 
            title="Add New Podcast" 
            icon={<FaPlus className="text-white text-2xl" />} 
            link="/add-podcast" 
            bgColor="bg-green-500"
          />
          <DashboardCard 
            title="View Blogs" 
            icon={<FaBlog className="text-white text-2xl" />} 
            link="/blogs" 
            bgColor="bg-purple-500"
          />
          <DashboardCard 
            title="View Podcasts" 
            icon={<FaPodcast className="text-white text-2xl" />} 
            link="/podcast" 
            bgColor="bg-yellow-500"
          />
          
        </div>

        {/* Leads Section */}
        <div className="mt-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Leads</h2>
          <div className="mb-4 flex items-center">
            <input
              type="text"
              placeholder="Search leads..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
            <select
              value={ageBracket}
              onChange={(e) => setAgeBracket(e.target.value)}
              className="border border-gray-300 rounded-md p-2 ml-2"
            >
              <option value="">All Age Brackets</option>
              <option value="18-25">18-25</option>
              <option value="26-35">26-35</option>
              <option value="36-45">36-45</option>
              <option value="46+">46+</option>
            </select>
            <button onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')} className="ml-2 bg-blue-500 text-white px-4 py-2 rounded-md">
              Sort by Name
            </button>
            <FaSearch className="ml-2 text-gray-500" />
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300 shadow-lg">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Name</th>
                  <th className="py-3 px-6 text-left">Email</th>
                  <th className="py-3 px-6 text-left">Inquiry</th>
                  <th className="py-3 px-6 text-left">Age Bracket</th> {/* New column */}
                  <th className="py-3 px-6 text-left">Created At</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {sortedLeads.length > 0 ? (
                  sortedLeads.map((lead) => (
                    <tr key={lead.id} className="border-b border-gray-200 hover:bg-gray-100">
                      <td className="py-3 px-6">{lead.name}</td>
                      <td className="py-3 px-6">{lead.email}</td>
                      <td className="py-3 px-6">{lead.inquiry}</td>
                      <td className="py-3 px-6">{lead.age_bracket}</td> {/* Updated field name */}
                      <td className="py-3 px-6">{new Date(lead.created_at).toLocaleString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="py-3 px-6 text-center text-gray-500">No leads found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
