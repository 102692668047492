import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';

// Pages
import Home from './pages/Home';
import Services from './pages/Services';
import Projects from './pages/Projects';
import About from './pages/About';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './pages/Dashboard';
import AddBlog from './components/AddBlog';
import AddPodcast from './components/AddPodcast';
import EditPodcast from './components/EditPodcast'; // Import the EditPodcast component
import BlogPost from './pages/BlogPost';
import PodcastList from './pages/PodcastList';
import Podcast from './pages/Podcast'; // Import the new Podcast component
import { supabase } from './supabaseConfig'; // Added import for Supabase

function App() {
  const location = useLocation();
  const [session, setSession] = useState(null);

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
    };

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-bgLight">
      <Header />
      <main className="flex-grow">
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogPost />} />
            <Route path="/podcast" element={<PodcastList />} />
            <Route path="/podcasts/:id" element={<Podcast />} /> 
            <Route path="/login" element={!session ? <Login /> : <Navigate to="/dashboard" />} />
            <Route path="/register" element={!session ? <Register /> : <Navigate to="/dashboard" />} />
            <Route 
              path="/dashboard" 
              element={
                <PrivateRoute session={session}>
                  <Dashboard />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/add-blog" 
              element={
                <PrivateRoute session={session}>
                  <AddBlog />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/add-podcast" 
              element={
                <PrivateRoute session={session}>
                  <AddPodcast />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/edit-podcast/:id" // Added route for editing podcasts
              element={
                <PrivateRoute session={session}>
                  <EditPodcast />
                </PrivateRoute>
              } 
            />
            <Route path="*" element={<Navigate to="/" />} /> // Catch-all route for undefined paths
          </Routes>
        </AnimatePresence>
      </main>
      <Footer />
    </div>
  );
}

export default App;