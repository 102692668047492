import React, { useEffect, useRef, useState } from 'react';
import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'react-router-dom';
import LeadCaptureForm from '../components/LeadCaptureForm';
import BlogPreview from '../components/BlogPreview';
import ProjectCounter from '../components/ProjectCounter';
import TestimonialCarousel from '../components/TestimonialCarousel';
import { FaAward, FaUsers, FaHardHat, FaWhatsapp } from 'react-icons/fa';

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.2]);
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);

  const heroRef = useRef(null);
  const projectsRef = useRef(null);
  const whyChooseRef = useRef(null);
  const testimonialsRef = useRef(null);

  const [currentHeroSlide, setCurrentHeroSlide] = useState(0);
  const heroSlides = [
    { title: "NatGen Contractors", subtitle: "Your Trusted Partner in Construction", image: "/images/hero1.jpg" },
    { title: "Building Dreams", subtitle: "Crafting Excellence in Every Project", image: "/images/hero1.jpg" },
    { title: "Innovative Design", subtitle: "Pushing Boundaries in Construction", image: "/images/hero2.jpg" },
    { title: "Sustainable Future", subtitle: "Eco-Friendly Building Solutions", image: "/images/hero3.jpg" },
  ];

  const [showLeftPopup, setShowLeftPopup] = useState(false);
  const [showLeadForm, setShowLeadForm] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHeroSlide((prev) => (prev + 1) % heroSlides.length);
    }, 5000);

    // Show left popup after 10 seconds
    const leftPopupTimer = setTimeout(() => {
      setShowLeftPopup(true);
    }, 10000);

    // Show lead capture form after 15 seconds
    const leadFormTimer = setTimeout(() => {
      setShowLeadForm(true);
    }, 15000);

    return () => {
      clearInterval(interval);
      clearTimeout(leftPopupTimer);
      clearTimeout(leadFormTimer);
    };
  }, []);

  useEffect(() => {
    const heroTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: heroRef.current,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
      },
    });

    heroTimeline.to('.hero-text', { y: 100, opacity: 0 });

    gsap.from(whyChooseRef.current.children, {
      opacity: 0,
      y: 30,
      stagger: 0.1,
      duration: 0.8,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: whyChooseRef.current,
        start: 'top 80%',
      },
    });

    gsap.from(projectsRef.current.children, {
      opacity: 0,
      y: 30,
      stagger: 0.1,
      duration: 0.8,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: projectsRef.current,
        start: 'top 80%',
      },
    });

    gsap.from('.blog-preview', {
      opacity: 0,
      y: 30,
      duration: 0.8,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '.blog-preview',
        start: 'top 80%',
      },
    });
  }, []);

  const handleLeadFormSubmit = () => {
    localStorage.setItem('leadFormShown', 'true'); // Set lead form shown in local storage
    setShowLeadForm(false); // Hide lead form after submission
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <div className="overflow-hidden">
      <AnimatePresence>
        {showLeadForm && (
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="fixed top-20 right-0 z-50 w-96 bg-bgDark p-6 rounded-l-lg shadow-lg"
          >
            <button
              onClick={() => setShowLeadForm(false)}
              className="absolute top-2 right-2 text-textLight hover:text-accent"
            >
              &times;
            </button>
            <h3 className="text-2xl font-bold mb-4 text-accent">Get a Free Consultation</h3>
            <LeadCaptureForm onSubmit={handleLeadFormSubmit} />
          </motion.div>
        )}
      </AnimatePresence>

     

      <motion.div
        ref={heroRef}
        className="relative h-screen flex items-center justify-center overflow-hidden bg-primary"
        style={{ scale }}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={currentHeroSlide}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="absolute inset-0 z-0 bg-cover bg-center"
            style={{
              backgroundImage: `url(${heroSlides[currentHeroSlide].image})`,
              opacity,
            }}
            alt={heroSlides[currentHeroSlide].title} // Added alt attribute
          />
        </AnimatePresence>
        <div className="relative z-10 text-center text-textLight hero-text">
          <motion.img
            src="logo.png"
            alt="NatGen Contractors Logo"
            className="w-32 h-32 mx-auto mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          />
          <AnimatePresence mode="wait">
            <motion.h1
              key={currentHeroSlide}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="text-7xl font-bold mb-4 text-accent"
            >
              {heroSlides[currentHeroSlide].title}
            </motion.h1>
          </AnimatePresence>
          <AnimatePresence mode="wait">
            <motion.p
              key={currentHeroSlide}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-3xl mb-8"
            >
              {heroSlides[currentHeroSlide].subtitle}
            </motion.p>
          </AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.4 }}
            className="space-x-4 mb-16"
          >
            <Link
              to="/contact"
              className="bg-secondary hover:bg-opacity-90 text-textLight font-bold py-4 px-8 rounded-full transition duration-300 text-xl inline-block"
            >
              Start Your Project
            </Link>
            <Link
              to="/projects"
              className="bg-transparent border-2 border-textLight hover:bg-textLight hover:text-primary text-textLight font-bold py-4 px-8 rounded-full transition duration-300 text-xl inline-block"
            >
              View Projects
            </Link>
          </motion.div>
        </div>
      </motion.div>

      <AnimatePresence>
        {showLeftPopup && (
          <motion.div
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="fixed top-20 left-0 z-50 w-96 bg-bgDark p-6 rounded-r-lg shadow-lg"
          >
            <button
              onClick={() => setShowLeftPopup(false)}
              className="absolute top-2 right-2 text-textLight hover:text-accent"
            >
              &times;
            </button>
            <h3 className="text-2xl font-bold mb-4 text-accent">Why Choose Us?</h3>
            <ul className="space-y-4">
              <li className="flex items-center text-textLight">
                <FaAward className="mr-2 text-accent" />
                <span>20+ Years of Excellence</span>
              </li>
              <li className="flex items-center text-textLight">
                <FaUsers className="mr-2 text-accent" />
                <span>500+ Satisfied Clients</span>
              </li>
              <li className="flex items-center text-textLight">
                <FaHardHat className="mr-2 text-accent" />
                <span>Certified Professionals</span>
              </li>
            </ul>
            <motion.div
              className="mt-6"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link
                to="/about"
                className="bg-accent text-textDark font-bold py-2 px-4 rounded-full inline-block hover:bg-opacity-90 transition duration-300"
              >
                Learn More About Us
              </Link>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      

      <ProjectCounter />

      <section className="py-24 bg-bgDark text-textLight">
        <div className="container mx-auto px-4">
          <motion.h2 
            className="text-5xl font-bold mb-16 text-center text-accent"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Featured Projects
          </motion.h2>
          <motion.div 
            ref={projectsRef}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {[ 
              { title: 'Modern Office Complex', image: 'project9.jpg', description: 'A state-of-the-art office space designed for productivity and collaboration.' },
              { title: 'Luxury Residential Tower', image: 'project11.jpg', description: 'Elegant high-rise living with panoramic city views and premium amenities.' },
              { title: 'Sustainable Industrial Facility', image: 'project18.jpg', description: 'Eco-friendly manufacturing plant utilizing renewable energy sources.' },
            ].map((project, index) => (
              <motion.div 
                key={index} 
                className="bg-primary rounded-lg overflow-hidden shadow-2xl transform hover:-translate-y-2 transition-transform duration-300"
                variants={itemVariants}
              >
                <div className="relative h-64 overflow-hidden">
                  <img src={project.image} alt={project.title} className="w-full h-full object-cover transition-transform duration-500 hover:scale-110" />
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                    <p className="text-textLight text-2xl font-semibold text-center px-4">{project.title}</p>
                  </div>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2 text-accent">{project.title}</h3>
                  <p className="text-gray-300 mb-4">{project.description}</p>
                  <Link to={`/projects`} className="inline-block bg-secondary hover:bg-opacity-90 text-textLight font-bold py-2 px-4 rounded transition duration-300 text-sm">
                    Learn More
                  </Link>
                </div>
              </motion.div>
            ))}
          </motion.div>
          <motion.div 
            className="text-center mt-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <Link to="/projects" className="inline-block bg-accent hover:bg-opacity-90 text-textDark font-bold py-4 px-8 rounded-full transition duration-300 text-xl shadow-lg hover:shadow-xl">
              View All Projects
            </Link>
          </motion.div>
        </div>
      </section>

      <section className="py-24 bg-[#064273] text-textLight">
        <div className="container mx-auto px-4">
          <h2 className="text-5xl font-bold mb-16 text-center text-white">Why Choose NatGen?</h2>
          <div ref={whyChooseRef} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[ 
              { title: 'Experience', icon: '🏆', description: 'Over 20 years in the industry' },
              { title: 'Quality', icon: '✨', description: 'Premium materials and craftsmanship' },
              { title: 'Innovation', icon: '💡', description: 'Cutting-edge construction techniques' },
              { title: 'Sustainability', icon: '🌿', description: 'Eco-friendly building practices' },
            ].map((item, index) => (
              <div key={index} className="text-center p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
                <div className="text-5xl mb-4">{item.icon}</div>
                <h3 className="text-2xl font-semibold mb-2 text-primary">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-24 bg-bgDark text-textLight">
        <div className="container mx-auto px-4">
          <h2 className="text-5xl font-bold mb-16 text-center text-accent">Latest Insights</h2>
          <div className="blog-preview">
            <BlogPreview />
          </div>
          <motion.div 
            className="text-center mt-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <Link to="/blog" className="inline-block bg-accent hover:bg-opacity-90 text-textDark font-bold py-4 px-8 rounded-full transition duration-300 text-xl shadow-lg hover:shadow-xl">
              View All Posts
            </Link>
          </motion.div>
        </div>
      </section>

      <section className="py-24" style={{ backgroundColor: '#76b6c4' }}>
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-5xl font-bold mb-8">Ready to Start Your Project?</h2>
          <p className="text-2xl mb-12 max-w-3xl mx-auto">Let's bring your vision to life. Contact us today for a free consultation and take the first step towards your dream project.</p>
          <Link to="/contact" className="inline-block bg-primary hover:bg-opacity-90 text-textLight font-bold py-4 px-8 rounded-full transition duration-300 text-xl">
            Get in Touch
          </Link>
          <div className="mt-12">
            <LeadCaptureForm />
          </div>
        </div>
      </section>

      {/* <section className="py-16 bg-bgDark text-textLight">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-center">What Our Clients Say</h2>
          <TestimonialCarousel />
        </div>
      </section> */}

      

      {/* <footer className="bg-[#4f42b5] text-white text-center py-4">
        <p>&copy; {new Date().getFullYear()} NatGen Contractors. All rights reserved.</p>
      </footer> */}

      <a
        href="https://wa.me/+254725214390" // Replace with your WhatsApp number
        className="fixed bottom-4 left-4 z-50 bg-green-600 text-white p-4 rounded-full shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 flex items-center"
        aria-label="Chat with us on WhatsApp"
      >
        <FaWhatsapp className="w-6 h-6 mr-2" /> {/* Using React Icons for WhatsApp */}
        <span className="text-lg font-semibold">Chat with us!</span>
      </a>
    </div>
  );
};

export default Home;