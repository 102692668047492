import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FaUsers, FaBuilding, FaAward, FaGlobe } from 'react-icons/fa';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO

gsap.registerPlugin(ScrollTrigger);

const useCountUp = (end, duration = 2000) => {
  const [count, setCount] = useState(0);
  const timeRef = useRef(null);

  useEffect(() => {
    const step = (timestamp) => {
      if (!timeRef.current) timeRef.current = timestamp;
      const progress = timestamp - timeRef.current;
      const increment = Math.floor((progress / duration) * end);
      
      if (progress < duration) {
        setCount(Math.min(increment, end));
        requestAnimationFrame(step);
      } else {
        setCount(end);
      }
    };

    requestAnimationFrame(step);

    return () => {
      timeRef.current = null;
    };
  }, [end, duration]);

  return count;
};

const AchievementItem = ({ achievement, onClick }) => {
  const count = useCountUp(achievement.count);
  
  return (
    <motion.div
      className="text-center cursor-pointer bg-white p-6 rounded-lg shadow-lg"
      whileHover={{ scale: 1.05 }}
      onClick={onClick}
    >
      <achievement.icon className="text-5xl mb-4 text-primary mx-auto" />
      <span className="text-4xl font-bold text-secondary">{count}</span>
      <h3 className="text-xl font-semibold mt-2 text-gray-800">{achievement.title}</h3>
    </motion.div>
  );
};

const About = () => {
  const [selectedAchievement, setSelectedAchievement] = useState(null);

  const timelineRef = useRef(null);
  const valuesRef = useRef(null);
  const statsRef = useRef(null);

  const achievements = [
    { icon: FaUsers, count: 500, title: 'Employees', description: 'Skilled professionals dedicated to excellence in construction.' },
    { icon: FaBuilding, count: 1000, title: 'Projects Completed', description: `From residential to commercial, we've built dreams across the nation.` },
    { icon: FaAward, count: 50, title: 'Awards Won', description: 'Recognition for our commitment to quality and innovation.' },
    { icon: FaGlobe, count: 20, title: 'Countries Served', description: 'Expanding our reach to bring quality construction worldwide.' },
  ];

  const journeyMilestones = [
    { year: 1995, event: 'NatGen Contractors founded' },
    { year: 2005, event: 'Expanded to commercial projects' },
    { year: 2010, event: 'Launched sustainable building initiative' },
    { year: 2015, event: 'Completed our 1000th project' },
    { year: 2023, event: 'Celebrating 28 years of excellence' },
  ];

  useEffect(() => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: timelineRef.current,
        start: 'top center',
        end: 'bottom center',
        scrub: true,
      },
    });

    timeline.to('.timeline-dot', {
      backgroundColor: '#FF6B6B',
      stagger: 0.5,
    });

    timeline.to('.timeline-line', {
      height: '100%',
      duration: 2,
    }, 0);

    gsap.from(valuesRef.current.children, {
      opacity: 0,
      y: 50,
      stagger: 0.2,
      duration: 1,
      ease: 'power3.out',
      scrollTrigger: {
        trigger: valuesRef.current,
        start: 'top 80%',
      },
    });

    gsap.from(statsRef.current.children, {
      scale: 0.5,
      opacity: 0,
      duration: 1,
      stagger: 0.2,
      ease: 'back.out(1.7)',
      scrollTrigger: {
        trigger: statsRef.current,
        start: 'top 80%',
      },
    });

    gsap.from('.journey-item', {
      opacity: 0,
      y: 50,
      stagger: 0.3,
      duration: 1,
      ease: 'power3.out',
      scrollTrigger: {
        trigger: timelineRef.current,
        start: 'top 80%',
      },
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>About NatGen Contractors</title>
        <meta name="description" content="Learn about NatGen Contractors, our achievements, journey, and values in the construction industry." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/about" />
        <meta property="og:title" content="About NatGen Contractors" />
        <meta property="og:description" content="Discover our achievements, journey, and values in the construction industry." />
        
        <meta property="og:url" content="https://NatGenContractors.com/about" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About NatGen Contractors" />
        <meta name="twitter:description" content="Discover our achievements, journey, and values in the construction industry." />
        <meta name="twitter:image" content="https://NatGenContractors.com/logo.jpg" /> {/* Replace with your image URL */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "NatGen Contractors",
            "url": "https://NatGenContractors.com",
            "logo": "https://NatGenContractors.com/logo.jpg", // Replace with your logo URL
            "sameAs": [
              "https://www.facebook.com/profile.php?id=61565317678639",
              "https://www.twitter.com/yourprofile",
              "https://www.linkedin.com/in/yourprofile"
            ]
          })}
        </script>
      </Helmet>
      <div className="min-h-screen bg-bgLight overflow-hidden">
        <section className="py-20 bg-primary text-white">
          <div className="container mx-auto px-4">
            <h1 className="text-6xl font-bold mb-8 text-center">About NatGen Contractors</h1>
            <p className="text-2xl max-w-3xl mx-auto text-center">
              Building dreams, shaping skylines, and crafting the future of construction since 1995. 
              At NatGen, we blend innovation with expertise to deliver exceptional results.
            </p>
          </div>
        </section>

        <section className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold mb-12 text-center text-primary">Our Achievements</h2>
            <div ref={statsRef} className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {achievements.map((achievement, index) => (
                <AchievementItem 
                  key={index}
                  achievement={achievement}
                  onClick={() => setSelectedAchievement(achievement)}
                  className="bg-accent hover:bg-coral transition duration-300" // Blend of colors
                />
              ))}
            </div>
          </div>
        </section>

        <section className="py-20 bg-bgLight">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold mb-12 text-center text-primary">Our Journey</h2>
            <div ref={timelineRef} className="relative">
              <div className="absolute left-1/2 w-1 h-full bg-primary transform -translate-x-1/2"></div>
              {journeyMilestones.map((milestone, index) => (
                <div key={index} className="journey-item flex items-center mb-8">
                  <div className={`w-1/2 ${index % 2 === 0 ? 'text-right pr-8' : 'ml-auto pl-8'}`}>
                    <h3 className="text-2xl font-bold text-primary">{milestone.year}</h3>
                    <p className="text-gray-800 text-lg">{milestone.event}</p>
                  </div>
                  <div className="w-4 h-4 bg-primary rounded-full absolute left-1/2 transform -translate-x-1/2"></div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <AnimatePresence>
          {selectedAchievement && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
              onClick={() => setSelectedAchievement(null)}
            >
              <motion.div
                className="bg-white p-8 rounded-lg max-w-md shadow-lg"
                onClick={(e) => e.stopPropagation()}
                role="dialog"
                aria-labelledby="achievement-title"
                aria-describedby="achievement-description"
              >
                <selectedAchievement.icon className="text-6xl mb-4 text-primary" />
                <h3 id="achievement-title" className="text-2xl font-bold mb-2 text-primary">{selectedAchievement.title}</h3>
                <p id="achievement-description" className="text-gray-600">{selectedAchievement.description}</p>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <section className="py-20 bg-gradient-to-b from-[#064273] to-[#76b6c4] text-white">
        <div className="container mx-auto px-4">
          <h2 className="text-5xl font-bold mb-12 text-center text-[#1da2d8]">Our Values</h2>
          <div ref={valuesRef} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {[
              { title: 'Integrity', description: 'Upholding the highest standards of honesty and transparency in all business practices.' },
              { title: 'Quality', description: 'Dedicated to delivering exceptional craftsmanship and maintaining rigorous quality control in every project.' },
              { title: 'Safety', description: 'Prioritizing the health and safety of our employees and clients with comprehensive protocols.' },
              { title: 'Innovation', description: 'Continuously seeking new technologies and methods to enhance efficiency and outcomes.' },
              { title: 'Customer Focus', description: 'Committed to understanding and exceeding client expectations through exceptional service.' },
              { title: 'Sustainability', description: 'Adopting practices that support environmental stewardship and resource efficiency.' },
              { title: 'Teamwork', description: 'Cultivating a collaborative work culture where every team member contributes to our success.' },
            ].map((value, index) => (
              <motion.div
                key={index}
                className="bg-white rounded-lg p-6 shadow-lg flex flex-col items-center transition duration-300 ease-in-out"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <h3 className="text-[#064273] text-2xl font-semibold mb-2">{value.title}</h3>
                <p className="text-gray-800 text-center">{value.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 bg-gradient-to-b from-[#064273] to-[#76b6c4] text-gray-800">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-4xl font-bold mb-8">Join Our Team</h2>
            <p className="text-xl mb-12 max-w-3xl mx-auto">
              We're always looking for talented individuals to join our growing team. If you're passionate about construction and want to be part of something great, we'd love to hear from you.
            </p>
            <motion.a
              href="/contact"
              className="inline-block bg-white text-[#064273] hover:bg-opacity-90 font-bold py-3 px-8 rounded-full transition duration-300 text-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Check For Open Positions
            </motion.a>
          </div>
        </section>
      </div>
    </>
  );
};

export default About;