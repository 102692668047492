import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseConfig';
import { FaEdit, FaSpinner, FaUpload, FaImage } from 'react-icons/fa';
import Helmet from 'react-helmet'; // For SEO optimization

const EditPodcast = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [author, setAuthor] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [audioFile, setAudioFile] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [currentAudioUrl, setCurrentAudioUrl] = useState('');
  const [currentImageUrl, setCurrentImageUrl] = useState('');

  useEffect(() => {
    fetchPodcast();
  }, [id]);

  const fetchPodcast = async () => {
    try {
      const { data, error } = await supabase
        .from('podcast')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;

      if (data) {
        setTitle(data.title);
        setDescription(data.description);
        setCategory(data.category);
        setAuthor(data.author);
        setYoutubeUrl(data.youtube_url);
        setCurrentAudioUrl(data.audio_url);
        setCurrentImageUrl(data.cover_image_url);
      } else {
        setError('Podcast not found');
      }
    } catch (error) {
      console.error('Error fetching podcast:', error);
      setError('Failed to fetch podcast');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    setError('');

    try {
      let audioUrl = currentAudioUrl;
      let imageUrl = currentImageUrl;

      if (audioFile) {
        const audioFileName = `${Date.now()}_${audioFile.name}`;
        const { error: audioError } = await supabase.storage
          .from('podcast_audio')
          .upload(audioFileName, audioFile);
        if (audioError) throw audioError;
        const { publicURL: newAudioUrl } = supabase.storage
          .from('podcast_audio')
          .getPublicUrl(audioFileName);
        audioUrl = newAudioUrl;
      }

      if (coverImage) {
        const imageFileName = `${Date.now()}_${coverImage.name}`;
        const { error: imageError } = await supabase.storage
          .from('podcast_covers')
          .upload(imageFileName, coverImage);
        if (imageError) throw imageError;
        const { publicURL: newImageUrl } = supabase.storage
          .from('podcast_covers')
          .getPublicUrl(imageFileName);
        imageUrl = newImageUrl;
      }

      const { error } = await supabase
        .from('podcast')
        .update({
          title,
          description,
          category,
          author,
          youtube_url: youtubeUrl,
          audio_url: audioUrl,
          cover_image_url: imageUrl,
          updated_at: new Date()
        })
        .eq('id', id)
        .select();

      if (error) throw error;

      navigate('/podcasts'); // Redirect to podcast list or view page
    } catch (error) {
      console.error('Error updating podcast:', error);
      setError('Failed to update podcast');
    } finally {
      setIsSaving(false);
    }
  };

  const handleAudioChange = (e) => {
    if (e.target.files[0]) {
      setAudioFile(e.target.files[0]);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setCoverImage(e.target.files[0]);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-4xl text-indigo-600" />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Edit Podcast - {title}</title>
        <meta name="description" content={`Edit the podcast titled "${title}"`} />
        <link rel="canonical" href={`https://myblog.com/podcasts/edit/${id}`} />
      </Helmet>
      <div className="bg-gray-100 min-h-screen pt-20 pb-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-2xl overflow-hidden">
          <div className="px-6 py-8 sm:p-10">
            <h2 className="text-3xl font-extrabold text-gray-900 mb-6">Edit Podcast</h2>
            {error && (
              <div className="mb-4 p-4 bg-red-100 text-red-700 rounded">
                {error}
              </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div>
                <label htmlFor="author" className="block text-sm font-medium text-gray-700">
                  Author
                </label>
                <input
                  type="text"
                  id="author"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div>
                <label htmlFor="youtube_url" className="block text-sm font-medium text-gray-700">
                  YouTube URL
                </label>
                <input
                  type="url"
                  id="youtube_url"
                  value={youtubeUrl}
                  onChange={(e) => setYoutubeUrl(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                  Category
                </label>
                <select
                  id="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                >
                  <option value="">Select a category</option>
                  <option value="technology">Technology</option>
                  <option value="business">Business</option>
                  <option value="entertainment">Entertainment</option>
                  <option value="education">Education</option>
                  <option value="lifestyle">Lifestyle</option>
                </select>
              </div>
              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  id="description"
                  rows="4"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                ></textarea>
              </div>
              <div>
               
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <>
                      <FaSpinner className="animate-spin mr-2" />
                      Saving...
                    </>
                  ) : (
                    <>
                      <FaEdit className="mr-2" />
                      Update Podcast
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPodcast;