import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://wweubgwqsftzxnijluah.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Ind3ZXViZ3dxc2Z0enhuaWpsdWFoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjU5ODg3ODQsImV4cCI6MjA0MTU2NDc4NH0.6pDUTnV4Bb1yE04eToT43JcXuggB8V0TAyPAdfu_R0k'

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export const getCurrentUser = async () => {
  const { data: { user } } = await supabase.auth.getUser()
  return user
}

export const signIn = (email, password) => 
  supabase.auth.signInWithPassword({ email, password })

export const signUp = (email, password, name) => 
  supabase.auth.signUp({
    email,
    password,
    options: { data: { name } }
  })

export const signOut = () => supabase.auth.signOut()


export const addBlog = async (title, content) => {
  const { data, error } = await supabase.from('blogs').insert([{
    title,
    content,
    author: 'Anonymous', // Set a default author if not authenticated
    created_at: new Date().toISOString(),
  }]).select();

  if (error) throw error;
  return data;
};

export const getBlogs = async () => {
  const { data, error } = await supabase
    .from('blogs')
    .select('*')
    .order('created_at', { ascending: false })
  
  if (error) throw error
  return data
}

export const deleteBlog = async (id) => {
  const { error } = await supabase.from('blogs').delete().match({ id })
  if (error) throw error
}

export const updateBlog = async (id, updates) => {
  const { data, error } = await supabase
    .from('blogs')
    .update(updates)
    .match({ id })
    .select()
  
  if (error) throw error
  return data
}
