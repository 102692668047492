import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseConfig';
import { FaSpinner } from 'react-icons/fa';
import { FaRegHeart } from 'react-icons/fa'; // New icon for likes
import Helmet from 'react-helmet'; // For SEO optimization

const BlogPost = () => {
  const { id } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBlogPost();
  }, [id]);

  const fetchBlogPost = async () => {
    try {
      const { data, error } = await supabase
        .from('blog')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;

      setBlogPost(data);
      // Update views count
      await supabase
        .from('blog')
        .update({ views: data.views + 1 })
        .eq('id', id);
    } catch (error) {
      console.error('Error fetching blog post:', error);
      setError('Failed to load blog post. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLike = async () => {
    try {
      await supabase
        .from('blog')
        .update({ likes: blogPost.likes + 1 })
        .eq('id', blogPost.id);
      setBlogPost({ ...blogPost, likes: blogPost.likes + 1 }); // Update local state
    } catch (error) {
      console.error('Error updating likes:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-4xl text-indigo-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{blogPost.title} - My Blog</title>
        <meta name="description" content={blogPost.excerpt || blogPost.content.substring(0, 150)} />
        <link rel="canonical" href={`https://NatGenContractors.com/${blogPost.id}`} />
        <meta property="og:title" content={blogPost.title} />
        <meta property="og:description" content={blogPost.excerpt || blogPost.content.substring(0, 150)} />
        <meta property="og:image" content={blogPost.image_url || 'https://NatGenContractors.com/logo.jpg'} /> {/* Replace with your default image URL */}
        <meta property="og:url" content={`https://NatGenContractors.com/${blogPost.id}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blogPost.title} />
        <meta name="twitter:description" content={blogPost.excerpt || blogPost.content.substring(0, 150)} />
        <meta name="twitter:image" content={blogPost.image_url || 'https://NatGenContractors.com/logo.jpg'} /> {/* Replace with your default image URL */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": blogPost.title,
            "description": blogPost.excerpt || blogPost.content.substring(0, 150),
            "image": blogPost.image_url || 'https://NatGenContractors.com/logo.jpg', // Replace with your default image URL
            "author": {
              "@type": "Person",
              "name": blogPost.author
            },
            "datePublished": blogPost.created_at,
            "url": `https://NatGenContractors.com/${blogPost.id}`
          })}
        </script>
      </Helmet>
      <div className="bg-gradient-to-r from-blue-200 to-purple-300 min-h-screen pt-20 pb-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-8">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-4">{blogPost.title}</h1>
          <p className="text-lg text-gray-700 mb-6">{blogPost.content}</p>
          <div className="flex justify-between items-center text-sm text-gray-500">
            <div>
              By {blogPost.author} on {new Date(blogPost.created_at).toLocaleDateString()}
            </div>
            <button className="flex items-center text-indigo-600 hover:text-indigo-800" onClick={handleLike}>
              <FaRegHeart className="mr-1" /> Like ({blogPost.likes})
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;
