import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaUser } from 'react-icons/fa';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const navItems = [
    { name: 'Home', path: '/' },
    { name: 'Services', path: '/services' },
    { name: 'Projects', path: '/projects' },
    { name: 'About', path: '/about' },
    { name: 'Blogs', path: '/blogs' },
    { name: 'Podcasts', path: '/podcast' },
    { name: 'Contact', path: '/contact' },
  ];

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <header className="fixed w-full z-50 bg-primary bg-opacity-90 backdrop-blur-md text-textLight">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className="text-3xl font-bold text-accent">
            NatGen
          </Link>
          <nav className="hidden lg:flex space-x-6 items-center">
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className={`hover:text-accent transition duration-300 text-sm lg:text-base py-2 px-3 rounded ${
                  location.pathname === item.path ? 'text-accent font-semibold' : ''
                }`}
              >
                {item.name}
              </Link>
            ))}
            <Link
              to="/login"
              className={`hover:text-accent transition duration-300 ${
                location.pathname === '/login' ? 'text-accent font-semibold' : ''
              }`}
            >
              <FaUser className="w-6 h-6" />
            </Link>
          </nav>
          <button
            className="lg:hidden text-textLight focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>
      </div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="lg:hidden bg-primary bg-opacity-90 backdrop-blur-md"
          >
            <nav className="container mx-auto px-4 py-4 flex flex-col space-y-2">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className={`hover:text-accent transition duration-300 text-sm lg:text-base py-2 px-3 rounded ${
                    location.pathname === item.path ? 'text-accent font-semibold' : ''
                  }`}
                >
                  {item.name}
                </Link>
              ))}
              <Link
                to="/login"
                className={`hover:text-accent transition duration-300 ${
                  location.pathname === '/login' ? 'text-accent font-semibold' : ''
                }`}
              >
                <FaUser className="w-6 h-6" />
              </Link>
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;